import { CorpMember, CorpMemberPermission, TinyCorpMember } from '@/gateway/affiliate/CorpMembers'
import { AccountUseCase, IAccountUseCase } from '@/usecase/user/AccountUseCase'
import { CorpMemberTableElement } from '@/presentation/user/model/CorpMemberTableElement'
import i18n from '@/plugins/i18n'
import { Message, MessageBox } from 'element-ui'
import { CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import { FormValidator } from '@/presentation/FormValidator'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

export interface IAccountPresentation {
  admin: Array<CorpMemberTableElement>
  members: Array<CorpMemberTableElement>

  adminField: { email: string; phone_number: string }
  memberField: { email: string; phone_number: string }

  resetAddMemberField(): void

  addMember(formRefs: any, memberPermission: CorpMemberPermission): Promise<boolean>

  initialize(): Promise<void>
}

export class AccountPresentation implements IAccountPresentation {
  private useCase: IAccountUseCase
  private corpMembers: Array<TinyCorpMember>
  private formValidator: FormValidator = new FormValidator()
  admin: Array<CorpMemberTableElement>
  members: Array<CorpMemberTableElement>
  adminField: { email: string; phone_code: string; phone_number: string }
  memberField: { email: string; phone_code: string; phone_number: string }

  constructor() {
    this.useCase = new AccountUseCase()
    this.corpMembers = []
    this.admin = []
    this.members = []
    this.memberField = {
      phone_number: '',
      phone_code: '',
      email: ''
    }
    this.adminField = {
      phone_number: '',
      phone_code: '',
      email: ''
    }
  }

  private convertTableCorpMemberElement(corpMembers: Array<TinyCorpMember>): Array<CorpMemberTableElement> {
    return corpMembers.map(
      ({ id, email, phone_code: phoneCode, phone_number: phoneNumber, is_site_admin: isSiteAdmin }) => {
        return {
          id,
          email,
          phone_number: `(${phoneCode}) ${phoneNumber}`
          // edit:
          //   isSiteAdmin === 'N'
          //     ? {
          //         type: 'button',
          //         class: 'member-delete',
          //         text: i18n.t('commons.delete') as string,
          //         callback: async () => {
          //           if (!id) return
          //           const confirmMessage: string = i18n.t('notification.delete_confirm') as string
          //           const isConfirmed = await MessageBox.confirm(confirmMessage, {
          //             confirmButtonText: i18n.t('commons.do_delete') as string,
          //             confirmButtonClass: 'is-plain',
          //             cancelButtonText: i18n.t('commons.cancel') as string
          //           })
          //             .then(() => true)
          //             .catch(() => false)
          //           if (!isConfirmed) return
          //           const isSuccessResponse = await this.useCase.deleteCorpMember(email)
          //           if (!isSuccessResponse) return
          //           await this.initialize()
          //           Message.success(i18n.t('commons.delete_done') as string)
          //         }
          //       }
          //     : undefined
        }
      }
    )
  }

  resetAddMemberField(): void {
    this.memberField = {
      phone_number: '',
      phone_code: '',
      email: ''
    }
  }

  async addMember(formRefs: any, memberPermission: CorpMemberPermission): Promise<boolean> {
    const isValid = await this.formValidator.validate(formRefs)
    if (!isValid) return false
    const isAdmin = +memberPermission === CorpMemberPermission.ADMIN
    const existAdmin = isAdmin ? this.corpMembers.find(member => member.id === this.admin[0]?.id) : undefined
    const email = isAdmin ? this.adminField.email : this.memberField.email
    const phoneNumber = isAdmin ? this.adminField.phone_number : this.memberField.phone_number
    const phoneCode = isAdmin ? this.adminField.phone_code : this.memberField.phone_code
    const corpMember: CorpMember = {
      corp_id: MemberInfoGateway.getMemberInfo().corp_id || '',
      id: existAdmin ? existAdmin.id : undefined,
      permission: memberPermission,
      email,
      phone_number: +phoneNumber,
      phone_code: Number(phoneCode),
      status: existAdmin ? existAdmin.status : this.corpMembers[0]?.status || CorpStatus.FORM_COMPLETE
    }
    const responseCode = await this.useCase.addCorpMember(corpMember)
    const successResponse = responseCode === 200
    if (!successResponse) {
      if (responseCode === 23) Message.error(i18n.t('notification.email_overlapped') as string)
      return false
    }
    Message.success(i18n.t('commons.save_done') as string)
    return true
  }

  async initialize(): Promise<void> {
    this.corpMembers = await this.useCase.getCorpMembers()
    const admin = this.corpMembers.filter(corpMember => corpMember.is_site_admin === 'Y')
    const members = this.corpMembers.filter(corpMember => corpMember.is_site_admin === 'N')
    this.admin = this.convertTableCorpMemberElement(admin)
    this.members = this.convertTableCorpMemberElement(members)
    if (!this.admin.length) {
      const email = await this.useCase.getTempEmail()
      this.adminField = { email, phone_number: '', phone_code: '' }
      return
    }
    this.adminField = {
      email: this.admin[0].email,
      phone_code: `${this.admin[0].phone_code}`,
      phone_number: this.admin[0].phone_number.toString()
    }
  }
}
