import MemberInfo from '@/models/MemberInfo'
import {
  CorpMember,
  CorpMembers,
  CreateCorpMembersParams,
  TinyCorpMember,
} from '@/gateway/affiliate/CorpMembers'
import { CorpAll } from '@/gateway/affiliate/model/CorpAll'
import { GetCorpAll } from '@/gateway/affiliate/GetCorpAll'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

export interface IAccountUseCase {
  getCorpMembers (): Promise<Array<TinyCorpMember>>;
  addCorpMember (member: CorpMember): Promise<number>;
  deleteCorpMember (email: string): Promise<boolean>;
  getTempEmail (): Promise<string>;
}

export class AccountUseCase implements IAccountUseCase {
  corpAll?: CorpAll = undefined

  private async getCorpAll (): Promise<CorpAll | undefined> {
    if (this.corpAll) return this.corpAll
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    this.corpAll = await GetCorpAll.getInstance().request({ corp_id: memberInfo.corp_id })
    return this.corpAll
  }

  async getTempEmail (): Promise<string> {
    const corpAll = await this.getCorpAll()
    return corpAll?.temp_email || ''
  }

  async getCorpMembers (): Promise<Array<TinyCorpMember>> {
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    const response = await CorpMembers.getInstance().getTiny({ corp_id: memberInfo.corp_id })
    return response.data.list
  }

  async addCorpMember (member: CorpMember): Promise<number> {
    const param: CreateCorpMembersParams = {
      list: [
        member
      ]
    }
    const response = await CorpMembers.getInstance().put(param)
    return response.code
  }

  async deleteCorpMember (email: string): Promise<boolean> {
    const response = await CorpMembers.getInstance().deleteByEmail(email)
    return response.code === 200
  }
}
